import './content3.scss';
import contentImg3 from '../../../img/page-contant/content3/ct111.jpg';
import contentImg3mob from '../../../img/page-contant/content3/ct111-mob.jpg'

const Content3 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content3');
 
	contentElement.innerHTML = `
	<div class='content3__container'>
	<img class="content3__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="content3__img" width="464" height="464" src="${contentImg3}">
	
	<div class="content3__body">
	<h2 class="content3__title">
	Какие преимущества у автоматического трейдинга при добыче альткоинов
	</h2>
		<p class="content3__text">
		Заработок на альткоинах может быть привлекательным, но требует понимания рынка, анализа рисков и выбора стратегий. Альтернативная криптовалюта представляет собой все монеты, которые отличаются от биткоина. Среди них есть как устоявшиеся монеты с хорошей репутацией, так и новые проекты, которые имеют хороший потенциал, и такие, что могут исчезнуть. На платформе для инвестиций в криптовалюту есть возможность получать пассивный доход, и это не копи-трейдинг, а торговля при помощи ИИ.
	</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content3;