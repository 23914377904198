import './contents1.scss';
import contentImg2 from '../../../img/page-contant-2/content2-1/ct2.jpg';
import contentImg2mob from '../../../img/page-contant-2/content2-1/ct2-mob.jpg'

const Contents1 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents1');
 
	contentElement.innerHTML = `
	<div class='contents1__container'>
	<img class="contents1__img-mob" width="432" height="210" src="${contentImg2mob}">

	
	<div class="contents1__body">
	<h2 class="contents1__title">

	Что говорят эксперты о платформе

	</h2>
		<p class="contents1__text">
		От экспертов в сети сейчас можно услышать о том, что существуют разные методы покупки цифровой валюты. О том как заработать на альткоинах говорится много, и среди всех вариантов следует выделить торговлю на основе ИИ. Автоматический трейдинг альткоинов или алгоритмическая торговля представляет собой процесс использования программного обеспечения для выполнения торговых операций по заранее заданным алгоритмам.<br>
		ИИ анализирует и обрабатывает большие объемы данных намного быстрее и точнее, чем человек, что позволяет выявлять рыночные возможности и реагировать на них практически мгновенно. При помощи ИИ есть возможность работы платформы для заработка на криптовалюте круглосуточно с непрерывным мониторингом рынка и торговлей, что повышает доход, использование сложных стратегий, которые невозможно реализовать вручную.
	</p>

	</div>
		<img class="contents1__img" width="580" height="650" src="${contentImg2}">

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents1;