import './contentf4.scss';
import contentImg from '../../../img/page-content-4/ct4444/ct4444.jpg';
import contentImgMob from '../../../img/page-content-4/ct4444/ct4444-mob.jpg';
const Contentf4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentf4');
 
	contentElement.innerHTML = `
	<div class='contentf4__container'>
	
	<img  class="contentf4__img-mob" src="${contentImgMob}" width="432" height="210" alt="img/jpg">
	<div class="contentf4__body">
	<h2 class="contentf4__title">
	Насколько реально заработать на крипте?

	</h2>
		<p class="contentf4__text">
		Платформе не требуется перерыв на сон, отдых. Искусственный интеллект может торговать круглосуточно, увеличивая прибыль от сделок, что не под силу инвесторам, занимающимся ручным трейдингом. Также такая торговля дает возможность новичкам зарабатывать деньги. Заработок на альткоинах действительно возможен. И торговля с ИИ делает его максимально простым и эффективным. <br>
		Чтобы начать добычу альткоинов, необходимо выполнить ряд простых действий. Они не занимают много времени - зарегистрироваться на сайте, внести в форму контактные данные (адрес электронной почты, номер телефона), ответить на звонок менеджера. Он расскажет о проекте, о его возможностях. После этого пополняется торговый счет, а сотрудники площадки помогут с настройкой торгового терминала, если у пользователя возникнут с этим сложности. Потом только нужно будет выводить профит.
		
		</p>
  

        
	</div>
	<img class="contentf4__img" src="${contentImg}" width="580" height="984" alt="img/jpg">	
    
	
	</div>
	<div class='contentf4__container'>
	
	
	<div class="contentf4__body">
	<h2 class="contentf4__title">

	Где можно заработать альткоины

	</h2>
		<p class="contentf4__text">
		Есть множество биржи и сайтов, где можно купить альткоины. Но при этом следует смотреть на то, законно ли работает площадка. Если с документами у нее все в порядке, то трейдер может приступать к покупке монет. Инвестировать рекомендуется в цифровую валюту, которая уже длительное время торгуется. Вложения в новые проекты на стадии их запуска могут принести многократные прибыли. Однако это только в том случае, если разработчикам удастся добиться желаемого. Но здесь есть риск, так как многие проекты не достигают своих целей. Если инвестор имеет альткоины, то он может их скинуть по цене выше, чем они были приобретены. Так как продать альткоины? А для этого необходимо зарегистрироваться на криптобирже или платформе, создать там учетную запись и перевести альткоины на кошелек. После этого создается ордер на продажу. Если с этим возникнут проблемы, эксперты нашей площадки готовы помочь и предоставить консультацию.

		</p>
		<p class="contentf4__text">
		Есть лучшие альткоины для инвестирования, и они проверены временем. Но даже так никто не может гарантировать 100% доход. Однако это при ручном трейдинге. Автоматическая торговля имеет по сравнению с ним много преимуществ - трейдинг в автоматическом режиме. В автоматическом режиме осуществляется подбор платформой стратегии и анализ рынка. При этом способе заработка полностью отсутствует эмоциональный фактор. На площадке есть возможность торговать в режиме 24/7, потому что для искусственного интеллекта не требуется время для отдыха и сна. Он торгует беспрерывно, а инвестор в это время может заниматься другими делами или отдыхать. Программное обеспечение совершает открытие ордеров по выгодным ценам.
		</p>

        
	</div>
	
    
	
	</div>
	<div class='contentf4__container'>
	
	
	<div class="contentf4__body">
	<h2 class="contentf4__title">

	Почему площадка подходит для начинающих трейдеров?

	</h2>
		<p class="contentf4__text">
		Криптовалюта является высоковолатильный активом. Это делает ее привлекательным инструментом торговли для инвестором, но в тоже время довольно рискованным. На ней можно заработать высокий профит, если удачно открыть сделку, но есть риск потерять все, потому что цена на нее может измениться в любую минуту. По этой причине не рекомендуется в нее вкладывать деньги новичкам. Но торговля на платформе с искусственным интеллектом полностью изменила эту ситуацию. Сейчас все больше начинающих трейдеров регистрируютися на платформе, потому что ИИ выполняет все действия вместо них, позволяя им зарабатывать на скачках курса крипты.

		</p>
		<p class="contentf4__text">
		На платформе есть возможность тестирования стратегий на исторических данных, что позволяет трейдерам оценить эффективность своей стратегии до ее реального применения на рынке. Изучение данных значительно экономит время трейдера, анализируя рынок и совершая сделки при помощи искусственного интеллекта. Платформа позволяет диверсифицировать инвестиционный портфель. Автоматический трейдинг альткоинов - отличная возможность получения пассивного дохода на криптовалютах. Покупка криптовалютных активов должна осуществляться на таких ресурсах, как криптобиржи, лицензированные брокерские компании. На нашей площадке эксперты подскажут где покупать альткоины и какие монеты выбрать, чтобы инвестиции принесли профит. Но главное, они порекомендуют площадку, где можно торговать в автоматическом режиме.

		</p>

        
	</div>
	
    
	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentf4;